import { makeStyles } from '@material-ui/core/styles';

const classes = {};

const useStyles = makeStyles({
  snackbar: {
    '& > div > div:first-child': {
      maxWidth: 338,
    },
  },
});

export default { classes, useStyles };
