import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from '../Avatar';
import Button, { ButtonVariant } from '../Button';
import Typography, { TypographyVariant } from '../Typography';
import IconButton, { IconButtonSize, IconButtonTheme } from '../IconButton';
import CallConstants from '../../constants/Call';
import { addCallingContact } from '../../slices/callingContact';
import SaleforceService from '../../Services/SaleforceService';
import TwilioVoiceService from '../../Services/TwilioVoiceService';
import CallCentreService from '../../Services/CallCentre/CallCentre';
import { useAppSelector, useAppDispatch } from '../../app/hooks';

import { IncomingCallPanelProps } from './IncomingCallPanel.types';
import IncomingCallPanelThemer from './IncomingCallPanel.theme';
import { faBuilding, faPhone, faPhoneHangup } from '@fortawesome/pro-solid-svg-icons';

const IncomingCallPanelComponent = forwardRef<HTMLDivElement, IncomingCallPanelProps>((props, ref) => {
    const { id, onCallAnswerPress, onCallHangupPress } = props;
    const classes = IncomingCallPanelThemer.useStyles();

  const dispatch = useAppDispatch();
  const authData = useAppSelector(state => state.auth);
  const incomingCallData = useAppSelector(state => state.callCentre.incomingCallInfo);
  const contactSearchByPhoneDataCount = incomingCallData?.contacts?.length || 0;

    // const incomingCallData = {
    //   callDetails: {
    //     agentName: 'Agent 1',
    //     unknownCaller: false,
    //     contactName: 'Prato Dewan',
    //     traversedRoutes: [],
    //     callForwardedByUserName: 'Test Manager',
    //     callFromNumber: '+441202158713',
    //     accountName: 'New Company',
    //   },
    //   callType: 'Customer',
    // };
  const callDetails = incomingCallData?.callDetails;
  const incomingCallType = incomingCallData?.callType;

  const locationNode = !(callDetails?.traversedRoutes) ? null : (
    <Typography.H4 variant={TypographyVariant.light}>
      {callDetails?.traversedRoutes?.map((route: any) => `${route.RouteCode} (${route.RouteName})`).join(' > ')}
    </Typography.H4>
  );

  const getProtentialContact = () => {
    let potentialContact;
    const contactRemoteId = callDetails?.contactRemoteId;

    if (incomingCallData?.contacts?.length > 0) {
      if (contactRemoteId) {
        potentialContact = incomingCallData?.contacts?.find((co: any) => co?.remoteId === contactRemoteId);
      }

      if (!potentialContact) {
        potentialContact = incomingCallData?.contacts?.[0];
      }
    }

    if (!potentialContact) {
      potentialContact = {contactType: "Unknown", remoteId: null, phone: callDetails?.contactNumber, firstName: "Unknown", lastName: "Caller"};
    }

    return potentialContact;
  };

  const potentialContact: any = getProtentialContact();

  const getCallerName = () => {
    let result = '';

    // If the call is from an agent, we have to handle it differently
    if (incomingCallType === CallConstants.IncomingCallTypeEnums.Agent) {
      result = callDetails?.agentName || 'Agent';
    } else if (incomingCallType === CallConstants.IncomingCallTypeEnums.Customer) {
      if (potentialContact?.remoteId) {
        result = `${potentialContact?.firstName || ''} ${potentialContact?.lastName || ''}`;
      } else {
        result = 'Unknown Caller';
      }
    }

    return result;
  };

  const getCallerAccount = () => {
    return `${potentialContact?.account?.name || ''}`;
  };

  const callerName = getCallerName();
  const callerAccount = getCallerAccount();

  const handleCallerAvatarClick = () => {
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
    let contactRemoteId = potentialContact?.remoteId;
    // Navigate to SF object details
    if (contactRemoteId) {
      SaleforceService.navigateToObject(contactRemoteId);
    } else {
      SaleforceService.callingObjectNavigation(callDetails?.contactNumber, callDetails?.contactId, false);
    }
  };

  const handleCallAnswerPress = () => {
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);

    const contactRemoteId = callDetails?.contactRemoteId;
    const sfContact = {...potentialContact, contactId: callDetails?.contactId}
    console.log("handleCallAnswerPress params: ", {contactRemoteId, sfContact})
    if (contactRemoteId !== sfContact?.remoteId) {
      let contactDetails = sfContact;
      
      if (!contactDetails || (typeof contactDetails === 'object' && Object.keys(contactDetails).length === 0)) {
        contactDetails = {contactType: 'Unknown', phone: callDetails?.contactNumber, contactId: callDetails?.contactId};
      }
      const callRequest = {CallId: callDetails?.callRecordId, ContactDetails: contactDetails};
      CallCentreService.assignObjectToCall(authData?.authUser.tenantCode, authData?.authUser.AccessToken, callRequest);
    }
    // Navigate to SF object details
    if (sfContact?.remoteId) {
      SaleforceService.navigateToObject(sfContact?.remoteId);
    } else {
      SaleforceService.callingObjectNavigation(callDetails?.contactNumber, callDetails?.contactId, false);
    }
    
    dispatch(addCallingContact(sfContact));
    onCallAnswerPress();
  };

  const didPressedMoreContacts = () => {
    if (incomingCallData?.callDetails?.contactNumber) {
      SaleforceService.callingObjectNavigation(incomingCallData?.callDetails?.contactNumber, '', true)
    }
  };

  const IncomingCallForwardInformation = (
    <div className={classes.callForwardInfo}>
      <Typography.H4 variant={TypographyVariant.light}>{`Forwarded by ${callDetails?.callForwardedByUserName}`}</Typography.H4>
      <Avatar
        id={`${id}-Call-Forward-Avatar`}
        style={{ width: 24, height: 24, marginLeft: 8 }}
        fallbackTextStyle={{ fontSize: 10, fontWeight: 600 }}
        url={callDetails?.callForwardedByUserName}
        fallbackLabel={callDetails?.callForwardedByUserName as string}
        alt="Forwarded Caller Avatar"
      />
    </div>
  );

  return (
    <div className={ classes.panelContainer } >
      <div className={ classes.callPanel } >
        <div className={ classes.callContent }>
          <div className={classes.contactDetailsContent}>
            <div onClick={handleCallerAvatarClick}>
              <Avatar
                id={`${id}-Caller-Avatar`}
                className={classes.avatar}
                alt="Incoming-Call-Panel-Caller Avatar"
                url={null}
                fallbackLabel={callerName}
                fallbackTextClassName={classes.avatarFallbackText}
              /> 
            </div>
            <div className={classes.contactDetails}>
              <div className={classes.contactName}>
                <Typography.H2 variant={TypographyVariant.light}>{callerName}</Typography.H2>
                {contactSearchByPhoneDataCount > 1 && (
                  <Button
                    id={`${id}-Contact-Count`}
                    style={{ color: '#FFFFFF' }}
                    variant={ButtonVariant.text}
                    onClick={didPressedMoreContacts}
                  >
                    {`(${contactSearchByPhoneDataCount - 1} other contacts)`}
                  </Button>
                )}
              </div>
              <Typography.H2 variant={TypographyVariant.light}>{incomingCallData?.callDetails?.contactNumber}</Typography.H2>
              {callerAccount &&
                <div className={classes.account}>
                  <FontAwesomeIcon icon={faBuilding}/>
                  <Typography.H4 variant={TypographyVariant.light}>{callerAccount}</Typography.H4>
                </div>
              }  
              
              {locationNode}
              {incomingCallData?.callDetails?.callForwardedByUserName && IncomingCallForwardInformation}
            </div>
          </div>
          <div className={classes.callActions}> 
            <IconButton id={`${id}-Answer-Button`} size={IconButtonSize.extraLarge} theme={IconButtonTheme.light} className={classes.answerButton} onClick={handleCallAnswerPress}>  
              <FontAwesomeIcon icon={faPhone} />
            </IconButton>  
            <IconButton id={`${id}-Hangup-Button`} size={IconButtonSize.extraLarge} theme={IconButtonTheme.light} className={classes.hangupButton} onClick={onCallHangupPress}>       
              <FontAwesomeIcon icon={faPhoneHangup} />
            </IconButton>      
          </div>
        </div>    
    </div>
    </div>      
  );
});

IncomingCallPanelComponent.defaultProps = {};

export default IncomingCallPanelComponent;