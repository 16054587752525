import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'moment';

import { hideSnackbar } from '../../../slices/snackbar';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import Typography, { TypographyVariant } from '../../Typography';
import IconButton, { IconButtonSize, IconButtonTheme } from '../../IconButton';
import { SnackbarType } from '../../../constants/SnackbarType';

import SnackbarThemer from './Snackbar.theme';

const ThemedSnackbar = withStyles(SnackbarThemer.classes as any)(Snackbar);

const SnackbarComponent = () => {
  const dispatch = useAppDispatch();
  const classes = SnackbarThemer.useStyles();
  const snackbarData = useAppSelector(state => state.snackbar);

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  const action = (
    <>
      <IconButton
        id='Snackbar-Close-Icon-Button'
        size={IconButtonSize.small}
        theme={IconButtonTheme.light}
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const resolveBookmarkTrigger = (type: any) => {
    let result = 'Bookmark triggered';

    if (type === 'ScheduleCallback') {
      result = 'Schedule callback bookmark triggered';
    } else if (type === 'Opportunity') {
      result = 'Opportunity bookmark triggered';
    } else if (type === 'Case') {
      result = 'Case bookmark triggered';
    }

    return result;
  };

  const resolveMessage = () => {
    let result;

    if (snackbarData?.type === SnackbarType.CoachingTip) {
      result = <Typography.P1 variant={TypographyVariant.light}>{`Your call with ${snackbarData?.data?.contactName} at ${Moment(snackbarData?.data?.date).format('HH:mm') || '-'} has now been analyzed.`}</Typography.P1>
    } else if (snackbarData?.type === SnackbarType.Bookmark) {
      result = <Typography.P1 variant={TypographyVariant.light}>{resolveBookmarkTrigger(snackbarData?.data?.bookmarkType)}</Typography.P1>
    } else if (!snackbarData?.type) {
      result = <Typography.P1 variant={TypographyVariant.light}>{snackbarData?.data?.message}</Typography.P1>
    }
    
    return result;
  };

  return (
    <div>
      <ThemedSnackbar
        id='Snackbar'
        className={classes.snackbar}
        open={snackbarData?.open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={resolveMessage()}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      />
    </div>
  );
};

export default SnackbarComponent;
