import Axios, { AxiosResponse } from 'axios';

import ApiHelper from '../../helpers/apiHelper';
import { store } from '../../app/store';
import { refreshToken } from '../../slices/auth';

const VoiceIQApiAxiosInstance = Axios.create();

VoiceIQApiAxiosInstance.interceptors.response.use(
  (response: AxiosResponse<any, any>) => {
    return ApiHelper.SuccessResponse(response);
  },
  (error) => {
    const originalRequest = error.config;

    // Check if the error status is 401 (Unauthorized) and there's no token refresh in progress
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Perform token refresh and retry the original request
      return refreshAccessToken().then((newToken) => {
        VoiceIQApiAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
        return Axios(originalRequest);
      });
    }

    // Reject the promise for other errors or when token refresh is unsuccessful
    return Promise.reject(error);
  }
);

// Function to refresh the token
function refreshAccessToken() {
  const authUserData = store.getState()?.auth?.authUser;
  const requestParams = {
    orgId: store.getState()?.auth?.authUser?.OrgId,
    accessToken: authUserData?.AccessToken,
    refreshToken: authUserData?.AccessToken,
  };

  const config = {
    baseURL: authUserData?.RPAPIGW_URL,
    headers: ApiHelper.createHeader(),
    url: '/connect/refresh',
    method: 'POST',
    data: requestParams,
  };

  return Axios.create().request(config)
    .then((response) => {
      const newAccessToken = response.data.accessToken;
      const newRefreshToken = response.data.refreshToken;

      store.dispatch(refreshToken({ accessToken: newAccessToken, refreshToken: newRefreshToken }));

      return newAccessToken;
    })
    .catch((error) => {
      console.error('Error refreshing token:', error);

      if (error.response?.status === 403) {
        // @ts-ignore
        sforce.opencti.runApex({
          apexClass: 'rocketphone.RPGetLoggedInUserInfo',
          methodName: 'getUserInfo',
          methodParams: '',
          callback: (response: any) => {
            if (response.success) {
              const authInfo = JSON.parse(response.returnValue.runApex) as  AuthInfo
              store.dispatch(refreshToken({ accessToken: authInfo?.AccessToken, refreshToken: authInfo?.RefreshToken }));

              return authInfo?.AccessToken;
            } else {
              console.error('Error refreshing token:', error);
            }
          }
        });
      }

      throw error;
    });
}

export type AuthInfo = {
  UserId: string;
  Username: string;
  Email: string;
  FullName: string,
  OrgId: string,
  OrgName: string,
  RPAPIGW_URL: string,
  StatusCode: string,
  Status: number,
  AccessToken: string,
  RefreshToken: string,
  ExpiresIn: number,
  TokenType: string,
};

export default VoiceIQApiAxiosInstance;
