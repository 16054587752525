import React, { forwardRef, useState, useEffect, useCallback } from 'react';

import CallRecord from '../../models/CallRecord';
import CallRecordService from '../../Services/Call/CallRecord';
import TwilioVoiceService from '../../Services/TwilioVoiceService';
import CallNotificationService from '../../Services/Call/CallNotification';
import { CallBarState } from '../../constants/CallBarState';
import { FinalizeEnum } from '../../constants/SkipWrapupEnum';
import { DefaultUserStatuses } from '../../constants/UserStatusesEnum';
import { getUserWrapupStatus, addLastCallRecord } from '../../slices/auth';
import { updateCallBarState, setIncomingCallIdle, setCallTimer, updateCurrentActiveCallRecordId, setIsCurrentCallInHold } from '../../slices/callCentre';
import TopNavBar from '../TopNavBar';
import HomeComponent from '../Home';
import CallOutcome from '../CallOutcome';
import NavigationDiallerContainer from '../NavigationDiallerContainer';
import IncomingCallPanel from '../IncomingCallPanel';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import SaleforceService  from '../../Services/SaleforceService';
import CallCentreService from "../../Services/CallCentre/CallCentre";
import { MainProps } from './Main.types';

const MainComponent = forwardRef<HTMLDivElement, MainProps>((props, ref) => {
  const [dialpadOpen, setDialpadOpen] = useState(false);
  const [showCallOutcome, setShowCallOutcome] = useState(false);
  const [selectedSfContact, setSelectedSfContact] = useState<null | any>(null);
  
  const authData = useAppSelector((state: any) => state.auth);
  const settingsData = useAppSelector((state: any) => state.settings);
  const callBarStateData = useAppSelector((state: any) => state.callCentre.callBarState);
  const callTimer = useAppSelector((state: any) => state.callCentre.callTimer);
  const isCurrentCallInHold = useAppSelector((state: any) => state.callCentre.isCurrentCallInHold);
  const callIncoming = useAppSelector(state => state.callCentre.incomingCallInfo?.callIncoming)
  const callingContact = useAppSelector(state => state.callingContact?.data)
  const isCallDispositionNotMandatory = useAppSelector((state: any) => state.settings?.tenantSettings?.tenantCallSettings?.isCallDispositionNotMandatory);
  const currentActiveCallRecordId = useAppSelector(state => state.callCentre?.currentActiveCallRecordId);
  const dispatch = useAppDispatch();
  
  const showNavigationDialler = !showCallOutcome &&
    callBarStateData !== CallBarState.IDLE &&
    callBarStateData !== CallBarState.WRAP_UP &&
    callBarStateData !== CallBarState.POST_CALL &&
    !!callBarStateData;

  const setupSForce = useCallback(() => {
     // @ts-ignore
     sforce?.opencti?.enableClickToDial({
      callback: (response: any) => {
        if (response.success) {
          console.log("API method call executed successfully! returnValue:", response.returnValue);
        } else {
           console.error('Something went wrong!', response.errors);
         }
       },
     });

    // @ts-ignore
    sforce?.opencti?.onClickToDial({
      listener: (payload: any) => {
        if (callBarStateData === CallBarState.IDLE) {
          SaleforceService.getObjectDetails(payload.objectType, payload.recordId)
            .then((result) => {
              result = {...result, dialNumber: payload.number};
              setSelectedSfContact(result);
            })
            .catch((error) => {
              const contact = { firstName: payload.recordName, mobilePhone: payload.number, remoteId: payload.recordId, contactType: payload?.objectType}
              setSelectedSfContact(contact);
            });
          SaleforceService.setSoftPhoneVisibleIfNot();
          console.log("payload: ", { payload });
          setDialpadOpen(true);
          setShowCallOutcome(false);
          /*
           {
            "url":
            "https://MyDomainNamemy.salesforce.com/lightning/r/Account/001x0000003DGQR/view",
            }
            "recordId": "001x0000003DGQR",
            "recordName": "Acme Person Account",
            "objectType": "Account",
            "contactId": "003D000000QOMqg",
            "personAccount": true
          }
           */
        }
      }
    });
  }, [callBarStateData, setDialpadOpen, setSelectedSfContact]);

  // End SForce work

  useEffect(() => {
    setupSForce();
  }, []);

  useEffect(() => {
    // we have to show call outcome page if the call was hung up from customer side
    if (callBarStateData === CallBarState.WRAP_UP || callBarStateData === CallBarState.POST_CALL) {
      setShowCallOutcome(true);
      setDialpadOpen(false);
    } else if (callBarStateData === CallBarState.IDLE) {
      setDialpadOpen(false);
    }
  }, [callBarStateData]);

  const handleDialPadClick = () => {
    setShowCallOutcome(false);
    setDialpadOpen(true);
  };

  const handleHomeClick = () => {
    setDialpadOpen(false);
    dispatch(updateCallBarState(CallBarState.IDLE))
  };

  const handleCallHangupClicked = () => {
    if (isCallDispositionNotMandatory) {
      dispatch(updateCallBarState(CallBarState.IDLE));
      setDialpadOpen(false);
    } else {
      dispatch(updateCallBarState(CallBarState.WRAP_UP))
      setShowCallOutcome(true);
    }
    
    setDialpadOpen(false);

    // dispatch(getUserWrapupStatus(authData.authUser?.userId));
   
    if (isCurrentCallInHold) {
      CallCentreService.hangupCall(currentActiveCallRecordId, authData?.authUser?.tenantCode, authData?.authUser?.AccessToken);

      dispatch(updateCurrentActiveCallRecordId(null));
      dispatch(setIsCurrentCallInHold(false));
      TwilioVoiceService.updateUserAvailableStatus(null, false);
    }
  };

  const handleNavigationDiallerUnmount = () => {
    setSelectedSfContact(null);
  };

  const finalizeCallRecord = async (callOutcome: string, callRecord: any, callDuration: any) => {
    const tenantUserStatusesState = settingsData?.tenantSettings?.userStatuses;
    const onCallStatus = tenantUserStatusesState?.filter((item: any) => item?.name === DefaultUserStatuses.OnCall)?.[0];
    const userOnCall = authData.user?.userStatus?.id === onCallStatus?.id;
    const finalizeRequest = new CallRecord.FinalizeRequest(callRecord?.callRecordId, FinalizeEnum.completed, callOutcome, callDuration);
    let result = true;

    try {
      await CallRecordService.finalizeCallRecord(authData?.authUser?.tenantCode, authData?.authUser?.AccessToken, finalizeRequest);
      dispatch(getUserWrapupStatus(authData.authUser?.userId));
      dispatch(addLastCallRecord(null));
      setShowCallOutcome(false);
      setDialpadOpen(false);
      
      if (userOnCall) {
        TwilioVoiceService.updateUserAvailableStatus(null, false);
      }
      dispatch(setCallTimer({ callDuration: 0 }));
    } catch (error) {
      result = false;
    }

    return result;
  }

  const handleCalloutcomeSubmitClick = (callOutcome: string) => {
    const callRecord = authData?.lastCallRecord ? authData?.lastCallRecord : {callRecordId: authData?.userWrapUpStatus?.callRecordID};
    dispatch(updateCallBarState(CallBarState.IDLE))

    finalizeCallRecord(callOutcome, callRecord, callTimer);
  };

  const handleCallWrapup = () => {
    setShowCallOutcome(true);
    setDialpadOpen(false);
  };
  
  const handleCallAnswer = useCallback(() => {
    dispatch(updateCallBarState(CallBarState.IN_CALL));
    dispatch(setIncomingCallIdle());
    setShowCallOutcome(false);
    // @ts-ignore
    TwilioVoiceService.callConnection?.accept();
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
    CallNotificationService.hideIncomingCallNotification(callDetails?.callRecordId);
  }, [dispatch]);

  const handleCallHangup = useCallback(() => {
    const callDetails = TwilioVoiceService.getCallDetailsFromConnection(TwilioVoiceService.callConnection);
    const activeCallRemoteId = TwilioVoiceService.getCallRemoteId(TwilioVoiceService.getActiveConnection());

    // If this is the only connection, go back to IDLE
    // No need to handle the else case because;
    // the CallBarState will not be changed to Incoming if the state wasn't IDLE
    if (callDetails?.remoteId === activeCallRemoteId) {
      dispatch(updateCallBarState(CallBarState.IDLE));
      TwilioVoiceService.updateUserAvailableStatus(null, false);
    }

    dispatch(setIncomingCallIdle());
    // CallHotTransferService.resetHotTransfer(true, true);
    // @ts-ignore
    TwilioVoiceService.callConnection?.reject();
    CallNotificationService.hideIncomingCallNotification(callDetails?.callRecordId);
  }, [dispatch]);

  const renderBodyContent = () => {
    let result = null;

    if (dialpadOpen || showNavigationDialler) {
      result = (
        <NavigationDiallerContainer
          id="Main-NavigationDiallerContainer"
          authData={authData}
          settingsData={settingsData}
          onCallHangup={handleCallHangupClicked}
          selectedSfContact={selectedSfContact}
          onNavigationDiallerUnmount={handleNavigationDiallerUnmount}
          onBackSpaceClicked={() => setSelectedSfContact(null)}
        />
      )
    } else if (showCallOutcome && !isCallDispositionNotMandatory) {
      const callRecord = authData?.lastCallRecord ? authData?.lastCallRecord : {callRecordId: authData?.userWrapUpStatus?.callRecordID};
      result = (
        <CallOutcome
          callRecord={callRecord}
          onCallOutcomeSubmit={handleCalloutcomeSubmitClick}
        />
      )
    } else {
      result = <HomeComponent onCallWrapup={handleCallWrapup}/>
    }

    return result;
  };

  return (
    <>
      {callIncoming && (
        <IncomingCallPanel id='IncomingCallPanel-Component' onCallAnswerPress={handleCallAnswer} onCallHangupPress={handleCallHangup}/>
      )}
      {!callIncoming && (
        <>
          {
            <TopNavBar
              id='TopNavBar'
              disableDialpad={callBarStateData !== CallBarState.IDLE}
              onDialPadClicked={handleDialPadClick}
              onHomeClicked={handleHomeClick}
            />
          }
          {renderBodyContent()}
        </>
      )}
    </>
  );
});

MainComponent.defaultProps = {
};

export default MainComponent;
