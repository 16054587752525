import React, { forwardRef, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Avatar, { AvatarSize } from '../Avatar';
import CallConstants from '../../constants/Call';
import { updateUserStatus } from '../../slices/auth';
import { showSnackbar } from '../../slices/snackbar';
import Typography, { TypographyVariant } from '../Typography';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { ReactComponent as RocketIcon } from '../../assets/viq-new-logo-white.svg';
import { DefaultUserStatuses, DefaultUserStatusesColours } from '../../constants/UserStatusesEnum';

import { TopNavBarProps } from './TopNavBar.types';
import TopNavBarThemer from './TopNavBar.theme';

const ThemedListItem = withStyles(() => (
  {
    selected: { backgroundColor: '#e7f3fc !important' },
    gutters: { paddingLeft: '12px', paddingTop: '7px', paddingBottom: '7px' },
  }
))(ListItem);

const TopNavBarComponent = forwardRef<HTMLDivElement, TopNavBarProps>((props: any) => {
  const { id, disableDialpad, onDialPadClicked, onHomeClicked } = props;
  const classes = TopNavBarThemer.useStyles(props);
  const authInfo = useAppSelector(state => state.auth);
  const tenantSettingsData = useAppSelector((state: any) => state.settings.tenantSettings);
  const teamWithOutGoingPreference = useAppSelector(state => state.auth.teamWithOutGoingPreference);
  const currentActiveCallRecordId = useAppSelector((state: any) => state.callCentre.currentActiveCallRecordId);
  const dispatch = useAppDispatch();
  
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorElUser);

  // User status variable
  const availableStatus = tenantSettingsData?.userStatuses?.filter((item: any) => item?.name === DefaultUserStatuses.Available);
  const availableId = availableStatus?.[0]?.id;
  const selectedStatusId = !!authInfo?.user?.userStatus?.id ? authInfo?.user?.userStatus?.id : availableId;

  useEffect(() => {
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(!anchorElUser ? event.currentTarget : null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleStatusItemAvailabilityClick = (status: any) => {
    // const onCallUserStatus = userStatues?.filter((item: any) => item?.name === DefaultUserStatuses.OnCall)?.[0];
    // || authInfo?.user?.userStatus?.id === onCallUserStatus?.id
    if (currentActiveCallRecordId) {
      const snackbarData = { message: 'Can not change user status while on a call'};
      dispatch(showSnackbar({ snackbarData }));
    } else {
      const userStatues = tenantSettingsData?.userStatuses;
      const availableStatusResult = userStatues?.filter((item: any) => item?.id === status?.id);
      const userStatuses = {
        UserStatusId: status?.id,
        Available: availableStatusResult?.[0]?.isAvailable,
        AwayFromDesk: availableStatusResult?.[0]?.isAwayFromDesk,
      };

      dispatch(updateUserStatus(userStatuses));
    }

    handleCloseUserMenu();
  };

  const renderAdminUserStatuses = (index: number, status: any, selectedStatusId: string) => {
    const selected = selectedStatusId === status?.id;

    return (
      <ThemedListItem
        id={`Admin-UserStatus-${index}`}
        key={`Admin-UserStatus-${index}`}
        button
        className={classnames(classes.menuListItem)}
        onClick={() => handleStatusItemAvailabilityClick(status)}
        // disabled={!!this.props.userInCall} // We have to disable while user in call
        selected={selected}
        disableGutters
      >
        <ListItemIcon className={classnames(classes.listIconWrapper)}>
          <FontAwesomeIcon icon={faCircle} className={classes.availabilityStatusIcon} color={status.colour} />
        </ListItemIcon>
        <ListItemText
          className={classnames(classes.listItemTextWrapper)}
          primary={
            <Typography.P1 className={classes.listItemText} variant={TypographyVariant.title} noWrap>{status.name}</Typography.P1>
          }
        />
        {
          status?.isAwayFromDesk && <Typography.P2 variant={TypographyVariant.label} className={classes.divertLabel}>Divert</Typography.P2>
        }
        {
          selected && (
          <ListItemIcon className={classnames(classes.listIconWrapper)}>
           <FontAwesomeIcon icon={faCheck} className={classes.checkIcon} />
          </ListItemIcon>
          )
        }
      </ThemedListItem>
    );
  };

  const renderStatuses = () => {
    const userPreferences = authInfo?.user?.preferences;
    const { userStatuses } = tenantSettingsData;
    const callDivertAvailable = !!(userPreferences?.callDivertingNumber)
                                && !!userPreferences?.callDivertingNumber?.isActive
                                && !!userPreferences?.callDivertingNumber?.number;

    const activeUserStatus = callDivertAvailable ? 
                             userStatuses?.filter((item: any) => item?.isSelectable && !item?.archived) || []
                            : userStatuses?.filter((item: any) => item?.isSelectable && !item?.archived && !item.isAwayFromDesk) || [];

    const result = activeUserStatus?.map((status: any, index: number) => renderAdminUserStatuses(index, status, selectedStatusId));

    return result;
  };

  const getNumberSelectedStatus = (numberState: {numberId: string, callerIdType: string }) => {
    if (!authInfo?.userTeam) {
      return numberState.numberId === authInfo?.user?.preferences?.outgoingCallIdPreference?.phoneNumberId &&
      numberState.callerIdType === authInfo?.user?.preferences?.outgoingCallIdPreference?.callerIdType;
    }

    return numberState.numberId === teamWithOutGoingPreference?.outgoingCallIdPreference?.phoneNumberId &&
      numberState.callerIdType === teamWithOutGoingPreference?.data?.outgoingCallIdPreference?.callerIdType;
  };

  const renderTeamHuntgroups = (team: any) => {
    const { huntGroups } = team;
    const teamHuntGroups = (
      <ol className={classes.orderedList}>
        {huntGroups.map((huntGroup: any) => {
          const isMember = !!huntGroup?.members?.find((member: any) => member?.userId === authInfo?.user?.id);

          return (
            <li id={`${huntGroup?.id}-Team-HuntGroups-List`} key={`${huntGroup?.id}-Team-HuntGroups-List`}>
              <Typography.P1 variant={TypographyVariant.navigation}>{huntGroup?.name}</Typography.P1>
              {isMember && (
                <Typography.P2 variant={TypographyVariant.label} className={classes.memberLabel}>(member)</Typography.P2>
              )}
            </li>
          );
        })}
      </ol>
    );

    return teamHuntGroups;
  };

  const companyCallNumberState = {
    numberId: tenantSettingsData?.phoneNumber?.id,
    callerIdType: CallConstants.OutgoingCallIdEnums.Organisation,
  };
  const teamNumberState = {
    numberId: authInfo?.userTeam?.id,
    callerIdType: CallConstants.OutgoingCallIdEnums.Team,
  };

  const activeUserStatus = tenantSettingsData?.userStatuses?.filter((status: any) => status?.id === selectedStatusId) || [];
  const activeUserStatusColor = (tenantSettingsData?.userStatuses?.length > 0) ? activeUserStatus?.[0]?.colour : DefaultUserStatusesColours.Available;
  const isUserOnCall = !!currentActiveCallRecordId;

  return (
      <AppBar position="static" sx={{ bgcolor: "#001932" }} className={ classes.topNavBar }>
        <Container>
          <Toolbar disableGutters className={ classes.navContainer }>
            <RocketIcon className={ classes.rpLogo } onClick={disableDialpad ? undefined : onHomeClicked} />
            <div>
              <IconButton
                size="large"
                sx={{mr: 0, position: 'relative', color: 'whitesmoke' }}
                disabled={disableDialpad}
                onClick={onDialPadClicked}
              >
                <DialpadIcon 
                sx={{display: 'inline-block', float: 'right'}} 
                />
              </IconButton>
              <IconButton
                onClick={handleOpenUserMenu}
                sx={{ p: 0, mr: 0, position: 'relative' }}
                style={{ paddingLeft: "5px" }}
              >
                <div>
                  <Avatar
                    id={`${id}-UserDropdown-Avatar`}
                    alt='User-Avatar'
                    url={authInfo?.user?.photoDetails?.photoUrl || null}
                    fallbackLabel={authInfo?.user?.displayName}
                    size={AvatarSize.medium}
                    disableToolTip
                  />
                  <span className={classes.statusDotWrapper}>
                    <span className={classes.statusDot} style={{ backgroundColor: isUserOnCall ?  DefaultUserStatusesColours.OnCall : activeUserStatusColor }}>
                      {
                        isUserOnCall && (
                          <FontAwesomeIcon
                            icon={faPhoneVolume}
                            className={classes.volumeIcon}
                          />
                        )
                      }
                    </span>
                  </span>
                </div>
                <Menu
                  id="account-menu"
                  anchorEl={anchorElUser}
                  className={classes.menu}
                  open={open}
                  onClose={handleCloseUserMenu}
                  onClick={handleCloseUserMenu}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      position:'fixed',
                      overflowColumn: 'scroll',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 0.8,
                      display: 'flex',
                      flexDirection: 'column',                     
                      '& .MuiMenuItem-root': {
                        maxWidth: '200px',
                        minHeight: '30px',
                        ml: 0,
                        mr: 0,
                        fontSize:'12px',
                      },
                      '& .MuiListItem-root': {
                        maxWidth: '42px',
                        height: '12px',
                        maxHeight: '42px',
                        ml: 0,
                        mr: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem className={classes.menuItemTitle} disabled sx={{ fontWeight: 'bold' }} disableGutters>
                    <ListItemIcon className={classes.listItemIcon}>
                      <PersonSearchIcon sx={{ color: 'black' }} fontSize="small" />
                    </ListItemIcon>
                    Your Status
                  </MenuItem>
                  {renderStatuses()}
                  <Divider />
                  <MenuItem className={classes.menuItemTitle} disabled sx={{fontWeight: 'bold'}} disableGutters>
                    <ListItemIcon className={classes.listItemIcon}>
                      <ManageAccountsIcon fontSize="small" sx={{ color: 'black' }} />
                    </ListItemIcon>
                    Your Phone Setup
                  </MenuItem>
                  <ThemedListItem
                    id={`${id}-Personal-Setting-Name`}
                    key="Personal-Setting-Name"
                    className={classes.firstThemeListItem}
                    button={true}
                  >
                    <ListItemText
                      className={classnames(classes.listItemContentTextWrapper)}
                      primary={
                        <Typography.H5 variant={TypographyVariant.title}>{authInfo?.user?.displayName || '-'}</Typography.H5>
                      }
                    />
                  </ThemedListItem>
                  <ThemedListItem
                    id={`${id}-Personal-Setting-DirectLine`}
                    key="Personal-Setting-DirectLine"
                    className={classes.firstThemeListItem}
                    button
                  >
                    <ListItemText
                      className={classnames(classes.listItemContentTextWrapper)}
                      primary={
                        <div className={classes.dropdownListItemPrimaryText}>
                          Direct Line
                          {
                            getNumberSelectedStatus({
                              numberId: authInfo?.user?.phone?.id,
                              callerIdType: CallConstants.OutgoingCallIdEnums.Personal,
                            }) && (
                              <span>(default dial out)</span>
                            )
                          }
                        </div>
                      }
                      secondary={<Typography.H5 variant={TypographyVariant.title}>{authInfo?.user?.phone?.number || '-'}</Typography.H5>}
                    />
                  </ThemedListItem>
                  {authInfo?.user?.mobilePhone?.number && 
                  <ThemedListItem
                  id={`${id}-Personal-Setting-mobilePhone`}
                  key="Personal-Setting-mobilePhone"
                  className={classes.firstThemeListItem}
                  button
                >
                  <ListItemText
                    className={classnames(classes.listItemContentTextWrapper)}
                    primary={
                      <div className={classes.dropdownListItemPrimaryText}>
                        Mobile Phone
                        {
                          getNumberSelectedStatus({
                            numberId: authInfo?.user?.mobilePhone?.id,
                            callerIdType: CallConstants.OutgoingCallIdEnums.Personal,
                          }) && (
                            <span>(default dial out)</span>
                          )
                        }
                      </div>
                    }
                    secondary={<Typography.H5 variant={TypographyVariant.title}>{authInfo?.user?.mobilePhone?.number || '-'}</Typography.H5>}
                  />
                </ThemedListItem>
                  }
                  <ThemedListItem
                    id={`${id}-Personal-Setting-TeamNumber`}
                    key="Personal-Setting-TeamNumber"
                    className={classes.firstThemeListItem}
                    button
                  >
                    <ListItemText
                      className={classnames(classes.listItemContentTextWrapper)}
                      primary={
                        <div className={classes.dropdownListItemPrimaryText}>
                          Team Number
                          {
                            getNumberSelectedStatus(teamNumberState) && (
                              <span>(default dial out)</span>
                            )
                          }
                        </div>
                      }
                      secondary={<Typography.H5 variant={TypographyVariant.title}>{authInfo?.userTeam?.number?.number || '-'}</Typography.H5>}
                    />
                  </ThemedListItem>
                  <ThemedListItem
                    id={`${id}-Personal-Setting-OrganisationNumber`}
                    key="Personal-Setting-OrganisationNumber"
                    className={classes.firstThemeListItem}
                    button
                  >
                    <ListItemText
                      className={classnames(classes.listItemContentTextWrapper)}
                      primary={
                        <div className={classes.dropdownListItemPrimaryText}>
                          Organisation Number
                          {
                            getNumberSelectedStatus(companyCallNumberState) && (
                              <span>(default dial out)</span>
                            )
                          }
                        </div>
                      }
                      secondary={<Typography.H5 variant={TypographyVariant.title}>{tenantSettingsData?.phoneNumber?.number || '-'}</Typography.H5>}
                    />
                  </ThemedListItem>
                  {!!(authInfo?.userTeam?.huntGroups?.length) && (
                    <ThemedListItem
                      id={`${authInfo?.userTeam?.id}-User-Team-HuntGroups`}
                      key={`${authInfo?.userTeam?.id}-User-Team-HuntGroups`}
                      className={classes.firstThemeListItem}
                      button
                    >
                      <ListItemText
                        className={classnames(classes.listItemContentTextWrapper)}
                        primary={<div className={classes.dropdownListItemPrimaryText}>Hunt Groups</div>}
                        secondary={renderTeamHuntgroups(authInfo?.userTeam)}
                      />
                    </ThemedListItem>
                  )}
                </Menu>
              </IconButton>
            </div>
          </Toolbar>
        </Container>
      </AppBar>
  );
});

TopNavBarComponent.propTypes = {
  id: PropTypes.string.isRequired,
  disableDialpad: PropTypes.bool,
};
TopNavBarComponent.defaultProps = {
  disableDialpad: false,
};

export default TopNavBarComponent;
